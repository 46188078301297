'use client';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';

import style from 'components/storyblok/Blok/Blok.module.scss';

export default function GlobalError({ error }: { error: Error & {digest?: string}}) {
    useEffect(() => {
        console.error('Error:', error)
        Sentry.captureException(error);
    }, [error])

    return (
        <div className={style['not-found']}>
            <h2>Något gick fel</h2>
            <Link className={style.button} href="/">Gå tillbaka hem</Link>
        </div>
    )
}
